<style lang="scss" scoped>
::v-deep .ant-input,
.ant-input {
  height: 40px;
}

::v-deep .ant-input-prefix {
  opacity: 0.5;
}
.required-identifier {
  color: red;
}
.ant-form-item-label--field-label {
  font-size: 12px;
  display: flex;
  gap: 4px;
  align-items: baseline;
  > p.suffix-label {
    font-size: 8px;
    display: flex;
    height: 8px;
    align-items: center;
  }
}
</style>

<template>
  <div>
    <a-input
      v-if="!customInput"
      :id="id"
      ref="ant-input"
      class="base-input"
      :value="inputDefaultValue"
      :placeholder="inputPlaceholder"
      :max-length="maxLength"
      :disabled="isDisabled"
      @change="(e) => onChangeValue(e)"
      @keypress="handleKeyPress"
      :type="type"
    >
      <template v-if="prefix" slot="suffix">
        {{ prefix }}
      </template>
    </a-input>

    <template v-else>
      <!-- VERTICAL FORM CONTENT -->
      <div v-if="formLabel" class="ant-col ant-form-item-label flex">
        <span class="ant-form-item-label--field-label">
          {{ formLabel }}
          <p v-if="suffixLabel" class="suffix-label">{{ suffixLabel }}</p>
        </span>
        <span class="required-identifier">{{ isRequired ? "*" : "" }}</span>
      </div>

      <a-form-item :id="fieldId" :validate-status="isError ? 'error' : ''" :help="isError ? fieldMsg : ''">
        <a-input-number
          v-if="useInputNumber"
          :default-value="value"
          :min="minNumber"
          :max="maxNumber"
          :formatter="handleFormatter"
          :parser="handleParser"
          :placeholder="inputPlaceholder"
          @change="(value) => onChangeValue(value)"
          @keydown="handleKeyPress"
        />
        <a-input
          v-else
          ref="ant-input"
          :value="value"
          :placeholder="inputPlaceholder"
          @keypress="handleKeyPress"
          @change="(e) => onChangeValue(e.target.value)"
        >
          <template v-if="prefix" slot="prefix">
            {{ prefix }}
          </template>
        </a-input>
      </a-form-item>
    </template>
  </div>
</template>

<script>
import { isEmpty, get } from "lodash";

export default {
  name: "BaseInput",
  components: {},
  props: {
    type: {
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
    onChangeValue: {
      type: Function,
      require: true,
    },
    inputPlaceholder: {
      type: String,
    },
    minNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    maxNumber: {
      type: Number,
      required: false,
      default: null,
    },
    maxLength: {
      type: Number,
      required: false,
      default: () => {
        "25";
      },
    },
    id: String,
    prefix: String,
    isDisabled: Boolean,
    useInputNumber: Boolean,
    customInput: {
      type: Boolean,
      default: false,
    },
    formLabel: String,
    fieldId: String,
    isRequired: Boolean,
    fieldMsg: String,
    suffixLabel: String,
  },
  data() {
    return {
      isError: false,
      inputVal: "",
    };
  },
  computed: {
    inputDefaultValue() {
      // if(this.type === "number" && this.value) {
      //   const defaultValue = parseFloat(this.value);
      //   return defaultValue.toFixed(2)
      // } else {
      //   return this.value;
      // }
      return this.value;
    },
    newVal: {
      get() {
        return this.inputVal;
      },
      set(value) {
        this.onChangeValue(value);
        this.inputVal = value;
        // VALIDATE
        if (isEmpty(value) && this.isRequired) {
          this.isError = true;
        } else {
          this.isError = false;
        }
      },
    },
  },
  created() {
    this.inputVal = this.value;
  },
  methods: {
    onChange(e) {
      const { value } = e.target;
      this.onChangeValue(value);
    },
    handleKeyPress($event) {
      if (this.type === "number") {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          // 46 is dot
          $event.preventDefault();
        }
      } else {
        this.$emit("keypress", $event);
      }
    },
    handleFormatter(value) {
      if (this.useInputNumber) {
        const minValue = get(this, "minNumber", 0);
        const maxValue = get(this, "maxNumber", 0);
        if (value > this.maxNumber) {
          return this.maxNumber;
        } else if (value < minValue) {
          return minValue;
        }
        return value;
      }
      return value;
    },
    handleParser(value) {
      if (this.useInputNumber) {
        const minValue = get(this, "minNumber", 0);
        const maxValue = get(this, "maxNumber", 0);
        if (value > this.maxNumber) {
          return this.maxNumber;
        } else if (value < minValue) {
          return minValue;
        }
        return value;
      }
      return value;
    },
  },
};
</script>
