<template>
  <div class="loading">
    <a-spin>
      <a-icon slot="indicator" type="loading" :class="{ 'is-medium': isMedium }" spin />
    </a-spin>
    <p class="text-info">{{ textInfo }}</p>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    isMedium: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.text-info {
  position: absolute;
  opacity: 0.9;
  top: 6rem;
  font-size: 16px;
  font-weight: 600;
}
.loading {
  display: flex;
  justify-content: center;
  min-height: 12em;
  align-items: center;
  width: 100%;
}
::v-deep .anticon-spin {
  font-size: 24px;
  color: #ff4d0a;
}

::v-deep .anticon-loading.is-medium .anticon-spin {
  font-size: 40px;
  color: #ff4d0a;
}
</style>
