<template>
  <a-modal
    v-if="isVisible"
    id="a-modal-content"
    centered
    class="a-modal"
    :visible="isVisible"
    :title="title"
    :footer="null"
    :closable="!isLoading && isClosable"
    :mask-closable="false"
    :width="customWidth"
    @cancel="handleClose()"
  >
    <a-spin tip="Loading..." :spinning="isLoading">
      <slot />
    </a-spin>
  </a-modal>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isClosable: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    handleClose: {
      type: Function,
      required: true,
    },
    customWidth: String,
  },
};
</script>
