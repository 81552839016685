<template>
  <div id="app" ref="main-app" :class="{ 'nav-visible': this.$store.getters.getOffCanvasMenuStatus }">
    <div id="app-canvas" direction="vertical">
      <a-modal
        v-if="openModal"
        v-model="openModal"
        class="session-expired-modal"
        title="Session Timeout"
        ok-text="OK"
        :footer="null"
        :closable="false"
        @ok="() => null"
      >
        <div class="modal-content">
          <div class="text-content">
            <font-awesome-icon class="icon" icon="exclamation-circle" />
            <p>Sorry!! Your session has timed out. Please log in again.</p>
          </div>
          <BaseButton type="primary" button-text="OK" :handle-click="() => reloadApp()" />
        </div>
      </a-modal>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/common/AntDesign/BaseButton.vue";

export default {
  name: "App",
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters("globalStore", ["getSessionExpired"]),
    openModal: {
      get() {
        return this.getSessionExpired;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
  mounted() {
    this.setSessionExpiration(this);
  },
  methods: {
    ...mapActions("globalStore", ["setSessionExpired"]),
    setSessionExpiration(component) {
      const tokenExpirationTime = localStorage.getItem("exp");
      if (tokenExpirationTime) {
        const tokenExpiration = Number(localStorage.getItem("exp")) * 1000;
        const expirationInterval = new Date(tokenExpiration) - new Date();
        setTimeout(async () => {
          await component.setSessionExpired(true);
        }, expirationInterval);
      }
    },
    reloadApp: function () {
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
  background-color: #f5f7fa;
}
#app-canvas {
  height: 100%;
  transform: translate3d(0, 0, 0);
  transition: ease all 300ms;
  backface-visibility: hidden;
}
.nav-visible {
  #app-canvas {
    transform: translate3d(300px, 0, 0);
  }
}
.session-expired-modal ::v-deep {
  .ant-modal-header {
    background: #ec5d57;
  }
  .ant-modal-title {
    color: white;
  }
  .modal-content .text-content {
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-bottom: 15px;
  }
  .modal-content .text-content svg {
    margin-right: 10px;
    color: #ec5d57;
    font-size: 22px;
  }
  .modal-content .text-content p {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .nav-visible {
    #app-canvas {
      transform: translate3d(300px, 0, 0);
    }
  }
}
</style>
