<template>
  <a-button class="button" :type="type" :loading="isLoading" :disabled="isDisabled" @click.prevent="handleClick">
    <font-awesome-icon v-if="icon !== 'none'" class="icon" :icon="icon" />
    {{ buttonText }}
  </a-button>
</template>

<script>
export default {
  name: "BaseButton",
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: () => {
        "";
      },
    },
    icon: {
      type: String,
      default: "none",
    },
    isLoading: Boolean,
    isDisabled: Boolean,
    buttonText: String,
    handleClick: Function,
  },
};
</script>

<style lang="scss" scoped>
button:disabled {
  opacity: 0.6;
}
</style>
