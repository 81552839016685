<style lang="scss" scoped>
@import "@/styles/components/_statuses.scss";
</style>

<template>
  <p v-if="status" class="status" :class="statusColorCode">
    {{ formatStatusDisplay }}
  </p>
</template>

<script>
// CONSTANTS
import STATUS_ID_CONSTANTS from "@/constants/status-id-constants";

export default {
  name: "DynamicStatus",
  props: {
    status: {
      type: String,
      required: true,
      default: STATUS_ID_CONSTANTS.INVOICE_STATUSES.FULFILLMENT_STATUSES.UNKNOWN.value,
    },
    statusType: {
      type: String,
      required: false,
    },
    maxWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusSize: String,
  },
  computed: {
    statusColorCode() {
      let status = "pending";
      let statusType = this.statusType;
      // Return the status as class name
      // Because in _statuses.scss, class names are based in the status name
      status = this.status;
      if (this.status) {
        status = this.status.toLowerCase().replace(/\s/g, "-").replace(/_/g, "-");
        status = status.replace(/\(|\)/g, "");
      }

      let smallTabletDisplay = ["fulfillment-order-history", "order-history", "reports"];
      if (smallTabletDisplay.includes(statusType) || this.statusSize === "small") {
        // small view statuses
        status = `${status} small-status`;
      }
      if (this.maxWidth) {
        // max width statuses
        status = `${status} width-max`;
      }
      return status;
    },
    formatStatusDisplay() {
      let formattedStatus = this.status;
      formattedStatus = formattedStatus.replace(/_/g, " ");
      return formattedStatus;
    },
  },
};
</script>
